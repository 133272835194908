* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
#exchange {
  padding: 3rem 0 1rem 0;
}
#exchange .hero-exchange {
  flex: 0 1 50%;
}

#step1 .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0;
  margin: 0;
}

.exchange-steps {
  display: flex;
  align-items: center;
  border: 1px solid #464646;
  border-radius: 100px;
  height: 60px;
  overflow: hidden;
  margin: 0 0 2rem 0;
  transition: all 0.3s ease;
}
.exchange-steps.final {
  border-radius: 24px;
  padding: 0 1.5rem;
  height: 92px;
  background-color: #68ffc8;
}
.exchange-steps__item {
  padding: 5px 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 0 1 33%;
  transition: all 0.5s ease;
}
.exchange-steps__item.current {
  background: #68ffc8;
}
.exchange-steps__item.current p {
  font-weight: 700;
  color: #000;
}
.exchange-steps__item.ex-current {
  background: #68ffc8;
}
.exchange-steps__item.ex-current > * {
  opacity: 0.5;
}
.exchange-steps__item.ex-current p {
  color: #000;
}
.exchange-steps__item.final {
  padding: 0;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
}
.exchange-steps__item.final p {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: clamp(1rem, 0.6429rem + 1.7857vw, 2.25rem);
  color: #000;
  gap: 1.5rem;
}
.exchange-steps__item.final p i::before {
  font-size: 30px;
}
.exchange-steps__item span {
  display: flex;
  width: 36px;
  height: 36px;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #171717;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
}
.exchange-steps__item p {
  font-weight: 400;
  font-size: 1rem;
  color: #b1b1b1;
}

.user-data {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.25rem;
  height: -moz-fit-content;
  height: fit-content;
}
.user-data span {
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  color: #cfcfcf;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.checkboxes .error {
  position: static;
}

.user-input label {
  font-weight: 700;
  font-size: 1rem;
  display: block;
  margin: 0 0 0.75rem 0;
}
.user-input__checkbox {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.user-input__checkbox input[type=checkbox] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 3px;
  z-index: 1;
}
.user-input__checkbox input[type=checkbox] + label {
  position: relative;
  margin: 0;
}
.user-input__checkbox input[type=checkbox] + label > span {
  flex: 0 0 18px;
  position: relative;
  width: 18px;
  height: 18px;
  margin: 3px 0 0 0;
  background: transparent;
  border: 2px solid #464646;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.user-input__checkbox input[type=checkbox] + label:hover > span,
.user-input__checkbox input[type=checkbox]:focus + label > span,
.user-input__checkbox input[type=checkbox]:hover + label > span {
  border-color: #68ffc8;
}
.user-input__checkbox input[type=checkbox]:checked + label > span {
  border: 0.6em solid #68ffc8;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.user-input__checkbox input[type=checkbox]:checked + label > span:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -5px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}
@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
.user-input__checkbox label {
  font-weight: 400;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1.125rem);
  margin: 0 0 0.75rem 0;
  display: flex;
  gap: 1.5rem;
}
.user-input__checkbox label a {
  white-space: nowrap;
  color: #68ffc8;
  position: relative;
}
.user-input__checkbox label a:hover::after, .user-input__checkbox label a:focus::after {
  opacity: 1;
}
.user-input__checkbox label a::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 90%;
  left: 0;
  pointer-events: none;
  background: #68ffc8;
  opacity: 0;
  transition: opacity 0.2s ease;
}

#step2 {
  margin: 0;
}
#step2 .container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem auto;
}
#step2 .container .selected-option {
  padding: 1rem 2.5rem 1rem 1rem;
}
#step2 .container .selected-option::before {
  display: none;
}
#step2 .container .hero-exchange .btn {
  display: none;
}

.exchange-inputs-wrapper {
  display: flex;
  gap: 1rem;
}
.exchange-inputs-wrapper > div {
  flex: 1 1 auto;
}
.exchange-inputs-wrapper > div input,
.exchange-inputs-wrapper > div .selected-option {
  cursor: default;
}
.exchange-inputs-wrapper > div h3 {
  margin: 0 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.wallet-address {
  display: flex;
  flex-direction: column;
}
.wallet-address label {
  font-weight: 700;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  margin: 0 0 0.5rem 0;
}
.wallet-address label span {
  color: #68ffc8;
}

.wallet-info {
  display: flex;
  gap: 1rem;
}
.wallet-info .input-wrapper {
  padding: 0 20px 0 0;
}
.wallet-info .input-wrapper input {
  font-weight: 400;
  font-size: clamp(0.875rem, 0.7679rem + 0.5357vw, 1.25rem);
  color: #aaa;
}
.wallet-info i {
  cursor: pointer;
  transition: box-shadow 0.2s ease;
}
.wallet-info i:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.qr {
  border: 1px solid #464646;
  border-radius: 12px;
  padding: 16px;
  transition: border 0.2s ease;
}
.qr:hover {
  border: 1px solid #68ffc8;
}

.exchange-status {
  display: flex;
  gap: 1rem;
  margin: 2rem 0 0 0;
}
.exchange-status > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1 1 auto;
  font-weight: 700;
  font-size: 1rem;
}
.exchange-status > div i[class="ri-refresh-line mainColored"]::before {
  animation: rotation 2s linear infinite;
  transform: scale(2);
}
.exchange-status > p {
  font-weight: 400;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  color: #cfcfcf;
}

.confirmation {
  position: fixed;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  background-color: #242424;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1.125rem;
  bottom: 10px;
  transition: all 0.5s ease-in;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3137254902);
}
.confirmation.successfull {
  opacity: 1;
}

.modal {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
  aspect-ratio: 1/1;
  min-width: 300px;
  padding: 3rem;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
}
.modal button {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
}
.modal button .close-btn {
  transform: scale(1.6);
  transition: color 0.3s ease;
}
.modal button .close-btn:hover {
  color: #68ffc8;
}
.modal img {
  max-width: 300px;
  max-height: 300px;
  min-width: 200px;
  min-height: 200px;
}

.exchange-details h3 {
  font-weight: 700;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  margin: 0 0 1.5rem 0;
}

.details-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.details-list li:nth-child(4) i, .details-list li:nth-child(5) i {
  display: none;
}
.details-list li span {
  font-weight: 400;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  color: #aaa;
}
.details-list li p {
  word-break: break-all;
  margin: 0.5rem 0 0 0;
  font-weight: 400;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  display: flex;
  gap: 1rem;
  align-items: center;
}
.details-list li p i {
  cursor: pointer;
}
.details-list li p i:hover::before {
  transition: all 0.2s ease;
  filter: drop-shadow(0 0 25px rgb(104, 255, 200));
}

#step3 .container {
  padding: 3rem;
}
#step3 .container .btn {
  margin: 2rem auto;
  box-shadow: 0 0 40px 0 rgba(104, 255, 200, 0.19);
  padding: 24px 36px;
  max-width: 892px;
  transition: box-shadow 0.2s ease;
}
#step3 .container .btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

@media (max-width: 880px) {
  #exchange {
    padding: 1rem 0;
  }
  #step1 .container {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .exchange-steps {
    margin: 0;
  }
  .exchange-steps__item:not(.current) {
    display: none;
  }
  .exchange-steps.final {
    margin-bottom: 1.5rem;
  }
  .exchange-steps__item.final {
    display: flex;
    justify-content: center;
  }
  .exchange-steps__item.current {
    flex: 1 1 100%;
    justify-content: center;
  }
  .exchange-inputs-wrapper {
    flex-direction: column;
  }
  .exchange-status {
    flex-direction: column;
  }
  #step3 .container {
    padding: 1rem 0;
  }
  #step3 .container .btn {
    margin: 1rem auto 2rem;
  }
}
@media (max-width: 400px) {
  .user-data {
    gap: 1.5rem;
  }
  .user-input__checkbox label {
    gap: 0.5rem;
  }
}/*# sourceMappingURL=Exchange.css.map */