@import '../../../sass/base/global';

.hero-exchange {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  flex: 0 1 600px;
  & > div:nth-child(1),
  > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  & > div h3 {
    color: #fff;
    font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-self: normal;
  }
  & .btn {
    position: relative;
    color: #000;
    font-size: $text-16;
    font-weight: 700;
    width: calc(100% - 4px);
    align-self: center;
    & i {
      @include centeredPseudoElement;
      right: 1rem;
    }
  }
}

// .form-control{
// 	max-height: 470px;
// 	overflow: auto;
// 	cursor: pointer;
// 	position: absolute;
// 	right: 16px;
// 	top: 50%;
// 	transform: translateY(-50%);
// 	color: #FFF;
// 	font-size: $text-20;
// 	font-weight: 500;
// 	&:focus{
// 		box-shadow: 0 14px 84px 0 rgba(0, 0, 0, 0.6);
// 		width: auto;
// 		left: -24px;
// 		top: calc(100% + 45px);
// 		right: -24px;
// 		padding: 24px;
// 		border-radius: 24px;
// 		background: #242424;
// 	}
// }

.exchange-swap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  & p,
  form {
    flex: 1 1 auto;
  }
  & .svg-container {
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #464646;
    transition: all 0.3s ease;
    &:hover {
      border: 1px solid $mainColor;
    }
    & svg {
      width: 24px;
      height: 24px;
    }
  }
}

.exchange-values {
  color: #cfcfcf;
  font-size: clamp($text-14, 0.7679rem + 0.5357vw, $text-20);
  font-weight: 400;
}

.exchange-type {
  display: flex;
  align-items: center;
  & label {
    cursor: pointer;
  }
}
.exchange-to {
  & .input-wrapper {
    & .input {
      padding: 16px 16px 16px 40px;
    }
    &::before {
      content: '~';
      @include centeredPseudoElement;
      left: 20px;
      font-size: 1.3rem;
    }
  }
}
// Custom inputs
.exchange-type input[type='radio'] {
  appearance: none; /* remove default */
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background-color: hsla(0, 0%, 0%, 0.2);
  background-image: -webkit-radial-gradient(transparent 25%, $mainColor 30%, $mainColor 100%);
  background-repeat: no-repeat;
  transition: background-position 0.15s cubic-bezier(0.8, 0, 1, 1), transform 0.25s cubic-bezier(0.8, 0, 1, 1), border 0.25s ease;
  outline: none;
  &:not(:checked) {
    border: 1px solid hsla(0, 0%, 100%, 0.15);
  }
  &:hover {
    border: 1px solid $mainColor;
  }
}
.exchange-type input[type='radio']:checked {
  transition: background-position 0.2s 0.15s cubic-bezier(0, 0, 0.2, 1), transform 0.25s cubic-bezier(0, 0, 0.2, 1);
  & + label {
    font-weight: 500;
  }
}
.exchange-type input[type='radio']:active {
  transition: transform 0.1s cubic-bezier(0, 0, 0.2, 1);
}

/* The left/right direction logic on radio inputs */

.exchange-type input,
.exchange-type input:active {
  background-position: 24px 0;
}
.exchange-type input:checked {
  background-position: 0 0;
}
.exchange-type input:checked ~ input,
.exchange-type input:checked ~ input:active {
  background-position: -24px 0;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.select-currency {
  & img {
    width: 24px;
    height: 24px;
  }
}
.selected-option {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 4.5rem 1rem 0;
  cursor: pointer;
  &::before {
    content: url('/src/images/arrow-down-s-fill.svg');
    position: absolute;
    top: 50%;
    transform: rotateX(0deg) translateY(-50%);
    right: 1rem;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  &[data-is-open='true'] {
    &::before {
      transform: rotateX(-180deg) translateY(50%);
    }
  }
  & span {
    font-weight: 700;
    font-size: clamp($text-14, 0.7679rem + 0.5357vw, $text-20);
    color: #fff;
  }
  & .web {
    color: black;
    font-size: 0.7em;
  }
}
.currency-search {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  order: -2;
  & h3 {
    font-weight: 700;
    font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
    order: 1;
    display: block;
  }
}

.currency-search-field {
  order: 2;
  flex: 1 1 auto;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  &.focused {
    & + h3 {
      display: none;
    }
    & input {
      opacity: 1;
      max-width: 100%;
    }
  }
  & input {
    flex: 1 1 auto;
    opacity: 0;
    max-width: 0;
    transition: all 0.3s ease;
    padding: 0 0 0.5rem 0;
    color: #fff;
    font-size: clamp($text-16, 0.9286rem + 0.3571vw, $text-20);
    border-bottom: 1px solid #cfcfcf;
    &::placeholder {
      font-weight: 400;
      font-size: $text-16;
      color: #b7b7b7;
    }
  }
  & i {
    height: 24px;
    width: 24px;
    pointer-events: none;
  }
}

.options {
  position: absolute;
  z-index: 2;
  top: calc(100% + 10px);
  left: -24px;
  right: 0;
  width: calc(100% + 48px);
  transition: all 0.3s ease;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0 14px 84px 0 rgba(0, 0, 0, 0.6);
  background: #242424;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: clip;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
  gap: 1rem;
  max-height: 400px;
}
.option {
  cursor: pointer;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #464646;
  transition: border 0.3s ease;
  &.selected {
    background-color: $mainColor;
    order: -1;
    & span {
      &:nth-child(2) {
        font-weight: 700;
        color: black;
      }
      &.web {
        border: 1px solid black;
      }
      &:last-child {
        color: black;
      }
    }
  }
  &:hover {
    border: 1px solid $mainColor;
  }
  & img {
    width: 24px;
    height: 24px;
  }
  & span {
    &:nth-child(2) {
      font-weight: 700;
      font-size: clamp($text-14, 0.7679rem + 0.5357vw, $text-20);
      color: #fff;
    }
    &:last-child {
      flex: 1 1 auto;
      text-align: right;
      font-weight: 400;
      font-size: $text-16;
      color: #b7b7b7;
    }
  }
}
.web {
  border: 1px solid $mainColor;
  background-color: $mainColor;
  border-radius: 12px;
  padding: 3px 5px;
  color: #000;
  font-size: 0.8em;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@media (max-width: 1070px) {
  .hero-exchange {
    flex: 1 1 auto;
  }
}
@media (max-width: 768px) {
  .hero-exchange .btn {
    padding: 20px 36px 20px 12px;
  }
}

@media (max-width: 560px) {
  .hero-exchange > div:nth-child(1),
  .hero-exchange > div:nth-child(3) {
    gap: 1rem;
  }
  .hero-exchange > div {
    padding: 1rem;
    border-radius: 16px;
  }
  .option {
    gap: 12px;
  }
}

@media (max-width: 400px) {
  .selected-option,
  .input {
    padding-block: 12px;
  }
  .input-wrapper {
    border-radius: 8px;
  }
  .exchange-to .input-wrapper .input {
    padding: 16px 16px 16px 32px;
  }
  .exchange-to .input-wrapper::before {
    left: 12px;
  }
  .hero-exchange .btn {
    padding: 12px 36px 12px 12px;
  }
}
