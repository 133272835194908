#footer{
	position: relative;
	background: #242424;
	margin: 1.5rem 0 0 0;
	&::before, &::after{
		content: '';
		display: block;
		position: absolute;
		transform: translateY(calc(-100% + 1px));
		width: 100svw;
		height: 24px;
		width: 24px;
		top: 0;
		background-color: #242424;
	}
	&::before{
		left: 0;
		clip-path: polygon(0% 0%, 0% 99%, 99% 99%, 87% 94%, 78% 91%, 68% 86%, 58% 81%, 47% 74%, 34% 64%, 21% 49%, 13% 33%, 7% 18%);
	}
	&::after{
		right: 0;
		clip-path: polygon(100% 0%, 100% 99%, 1% 99%, 13% 94%, 22% 91%, 32% 86%, 42% 81%, 53% 74%, 66% 64%, 79% 49%, 87% 33%, 93% 18%);
	}
	.container{
		padding: 3rem 1rem 3rem 1rem;
	}
	
}

.footer{
	display: flex;
	gap: 2rem 1rem;
	flex-wrap: wrap;
}
.footer-item{
	display: flex;
	flex-direction: column;
	gap: 1rem;
	flex: 1 1 20%;
	&:first-child{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	& .footer-heading{
		color: #FFF;
		font-size: $text-16;
		font-weight: 700;
		line-height: 131.25%; /* 21px */
		text-transform: capitalize;
		// margin: 0 0 1.15rem 0; /*  1.5-0.35=1.15 (minus links padding) */
	}
	& p{
		display: block;
		max-width: clamp(160px, 12vw, 100%);
		color: #FFF;
		font-size: $text-16;
		font-weight: 400;
		line-height: 137.5%; /* 22px */
	}
}
.footer-logo{
	width: 44px;
	height: 44px;
}
.footer-list{
	& li a{
		display: block;
		padding: 0.35rem 0.35rem 0.35rem 0;
		color: #BBB;
		font-size: $text-14;
		letter-spacing: 0.14px;
		transition: all .3s ease-in-out;
		&:hover{
			color: #FFF;
		}
	}
}

.social {
	display: flex;
	gap: .5rem;
	& li a{
		display: block;
		aspect-ratio: 1/1;
		padding: .75rem;
		border-radius: 12px;
		border: 1px solid #464646;
		transition: all .3s ease-in-out;
		&:hover{
			background-color: #464646;
		}
		& i{
			color: #FFF;
		}
	}
}


@media (max-width: 1200px) { 
	
}
@media (max-width: 1024px) { 
	.footer{
	}
	.footer-item{
	}
}
@media (max-width: 768px) { 
	.footer-item{
		flex: 1 1 40%;
	}
}
@media (max-width: 560px) {
	.footer-item{
		flex: 1 1 100%;
	}
}
@media (max-width: 400px) { 
	.footer-item{
		gap: .5rem;
	}
}
@media (max-width: 320px) {
	
} 