header{
	z-index: 10;
	height: 96px; 
	& .container{
		display: flex;
		align-items: center;
		gap: 2.25rem;
		padding: 1.5rem 0;
		max-height: 100%;
	}
}

.logo{
	max-height: 100%;
	max-width: 4rem;
	transition: filter .3s ease;
	&:hover{
		filter: drop-shadow(0 0 2px rgba(104, 255, 200, .7));
	}
}

.menu-list{
	display: flex;
	gap: 1rem;
}

.menu-link{
	display: block;
	padding: .75rem 1.5rem;
	color: #CDCDCD;
	font-weight: normal;
	transition: color .4s;
	font-size: $text-16;
	&:hover, &.active{
		color: #FFF;
	}
	
}

.buttons-wrapper{
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;
	& > *{
		align-self: center;
	}
	& .active{
		filter: drop-shadow(0 0 25px rgba(104, 255, 200, 0.4));
	}
}

 .__select {
	height: 100%;
	position: relative;
	&::before{
		content: url('/src/images/arrow-down-s-fill.svg');
		position: absolute;
		top: calc(50% - 12px);
		transform: rotateX(0deg);
		right: 16px;
		z-index: 2;
		pointer-events: none;
		transition: all .4s ease;
		display: block;
    	width: 24px;
    	height: 24px;
	}
	&.show {
		background-color: #242424;
		border-radius: 50px;
		&::before {
			transform: rotateX(180deg);
		}
	  .__select__content {
		opacity: 1;
    	pointer-events: all;
    	top: 25px;
	  }
	}
 }

 .__select__title {
	display: flex;
	padding: 12px 52px 12px 24px;
	align-items: center;
	gap: 16px;
	border-radius: 50px;
	background: #242424;
	box-shadow: 0px 2px 67.4px -1px rgba(0, 0, 0, 0.09);
	cursor: pointer;
	position: relative;
	z-index: 1;
 }
 
 .__select__content {
	position: absolute;
	top: calc(100% - 1px);
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 23px 8px 12px 8px;
	background-color: inherit;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	transition: opacity 0.1s ease-out, all .3s ease;
	opacity: 0;
	pointer-events: none;
	z-index: 0;
 }
 
 .__select__input {
	display: none;
 }
 .__select__label {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	padding: 0 16px;
	transition: all 0.2s ease-out;
	cursor: pointer;
 }

// Burger menu

.burger-menu{
	display: flex;
	& > input {
		display: none;
	}
	& #burger + label {
		cursor: pointer;
      position: absolute;
		top: 36px;
      right: 20px;
      height: 24px;
      width: 24px; 
      z-index: 5;
		display: block;
		z-index: 11;
      & span {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        margin-top: -1px;
        left: 0;
        display: block;
        background: #FFF;
        transition: .5s;
      }
      & span:first-child {
        top: calc(0px + 2px); 
      }
      & span:last-child {
        top: calc(100% - 2px); 
      }      
    }
	 & #burger:checked + label + nav + .buttons-wrapper{
			opacity: 1;
			pointer-events: all;
	 }
    & #burger:checked + label { 
      & span {
        opacity: 0;
        top: 50%;
		  background: #FFF;
      }
      & span:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }
      & span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }
    & nav {
		pointer-events: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      transition: .5s;
      transition-delay: .5s;
      overflow: hidden;
		background-color: transparent;
		z-index: -1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		& > ul {
      	text-align: center;
			display: flex;
			flex-direction: column;
        & > li {
          opacity: 0;
          transition: .5s;
          transition-delay: 0s;
			 & > a {
				font-size: clamp($text-24, 1.0714rem + 2.1429vw, $text-48);
            text-decoration: none;
            text-transform: uppercase;
            color: #FFF;
            font-weight: bold;
				&.active{
					color: $mainColor;
				}
          }
        }
      }
    }
    & #burger:checked ~ nav { 
		pointer-events: all;
      height: 100%;
      transition-delay: 0s;
		backdrop-filter: blur(20px);
      z-index: 10;
      & > ul {
			& > li {
          opacity: 1;
          transition-delay: .5s;
        }
      }
    } 
	 .buttons-wrapper{
		opacity: 0;
		pointer-events: none;
		z-index: 10;
		position: fixed;
		bottom: 1rem;
		left: 0;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100svw;
		transition: all .5s ease;
		& form {
			position: fixed;
			top: 34px;
		}
		& a{
			background: transparent;
			padding: 12px 24px 12px 12px;
			border: 2px solid #FFF;
			color: #FFF;
			& i:before{
				color: #FFF;
			}
		}
	 }
}


 @media (min-width: 1025px) and (max-width: 1200px) {
	header .container{
		gap: 1rem;
	}
	.buttons-wrapper .btn{
		padding: 12px 16px 12px 8px;
		gap: .5rem;
	}
 }

@media (min-width: 1024px) and (max-width: 1070px){ 
	.menu-link{
		padding: 0.75rem 1rem;
	}
}

@media (max-width: 1024px) { 
	.buttons-wrapper{
		margin: 0 1.5rem 0 0;
	}
	header .container{
		gap: 1rem;
	}
}

@media (max-width: 768px) { 
	
}
@media (min-width: 560px) and (max-width: 630px){
	.buttons-wrapper {
		gap: .5rem;
		& .btn{
			padding: 12px 18px 12px 12px;
		}
	}
 }
@media (max-width: 400px) { 
	
}