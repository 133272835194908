// Reset
* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}
img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
a {
  color: #fff;
}
*:focus {
  outline: none;
}

//font variables
$text-8: 0.5rem;
$text-12: 0.75rem;
$text-14: 0.875rem;
$text-16: 1rem;
$text-18: 1.125rem;
$text-20: 1.25rem;
$text-22: 1.375rem;
$text-24: 1.5rem;
$text-26: 1.625rem;
$text-28: 1.75rem;
$text-30: 1.875rem;
$text-32: 2rem;
$text-34: 2.125rem;
$text-36: 2.25rem;
$text-38: 2.375rem;
$text-40: 2.5rem;
$text-42: 2.625rem;
$text-44: 2.75rem;
$text-46: 2.875rem;
$text-48: 3rem;
$text-52: 3.25rem;
$text-56: 3.5rem;
$text-60: 3.75rem;
$text-64: 4rem;
// Colors
$mainColor: #68ffc8;
$greyText: #afafaf;
// Classes
:root {
}

@mixin centeredPseudoElement {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin hoverGlow {
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
  }
}

@mixin glowingIcon {
  height: 39px;
  width: 36px;
  &::before {
    font-size: 36px;
    filter: drop-shadow(0 0 35px rgba(104, 255, 200, 1));
  }
}
@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::selection {
  background-color: #ffffff3f;
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^="ri"]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp($text-32, 1.7143rem + 1.4286vw, $text-48);
  margin: 0 0 1.5rem 0;
}

.dfb {
  //? DeFault Block
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: $mainColor;
  color: #000;
  font-size: $text-16;
  font-weight: 500;
  @include hoverGlow;
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: $text-16;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: 0px 0px 50px 5px #406dffa4;
  }
}

.mainColored {
  color: $mainColor;
}

[class^="ri-"],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);

  display: block;
  padding: 3px;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;

  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

// Media queries
@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
