@import '../../sass/base/global';
// Steps 

.steps{
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(3, 1fr);
}
.steps-item{
	aspect-ratio: 1.2/1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
	&:last-child{
		background: $mainColor;
		color: #171717;
		& p span{
			color: $mainColor;
		}
		& h4 + span{
			color: #000;
		}
	}
	& p{
		flex: 1 1 auto;
		& span{
			display: flex;
			width: 62px;
			height: 62px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: #171717;
			font-size: clamp($text-16, 0.6429rem + 1.7857vw, $text-36);
			font-weight: 700;
			color: #FFF;
		}
	} 
	& h4{
		font-size: clamp($text-16, 0.6429rem + 1.7857vw, $text-36);
		font-weight: 700;
	}
	& span{
		color: #AFAFAF;
		font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
		font-weight: 400;
	}
}

@media (max-width: 1200px) {
	.steps-item{
		aspect-ratio: unset;
	}
}
@media (max-width: 768px) {
	.steps{
		grid-template-rows: repeat(3, 1fr);
		grid-template-columns: 1fr;
	}
}
@media (max-width: 400px) {
	.steps{
		grid-template-rows: repeat(3, auto);
	}
	.steps-item p span {
		width: 48px;
		height: 48px;
	}	
}