@import '../../../sass/main';

// Benefits

#benefits {
  margin: 0 0 8rem 0;
}
.benefits {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.benefits-item {
  position: relative;
  display: flex;
  padding: 24px;
  aspect-ratio: 1/1;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 1 25%;
  border-radius: 24px;
  background: #242424;
  background: linear-gradient(135deg, #242424 0%, #242424 52.6%, rgba(36, 36, 36, 0.61) 80.5%, rgba(36, 36, 36, 0) 100%);
  & i::before {
    filter: drop-shadow(0 0 35px rgb(104, 255, 200));
  }
}
i.big-icon {
  position: absolute;
  right: -13%;
  bottom: -18%;
  transform: rotate(-15deg);
  &::before {
    filter: none;
    position: relative;
    font-size: 200px;
    background-size: 100%;
    background: radial-gradient(circle farthest-corner at top left, #242424 6%, #0d0d0d 75%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: -1;
  }
}
.benefits-text {
  position: relative;
  z-index: 1;
  & h4 {
    font-size: clamp($text-16, 0.8929rem + 0.5357vw, $text-22);
    font-weight: 500;
    line-height: 163.63%; /* 36px */
  }
  & p {
    color: #afafaf;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    font-weight: 400;
    line-height: 150%;
  }
}

// Exchanges

#exchanges {
  .container {
    cursor: default;
  }
}
.exchanges {
  display: grid;
  grid-template: 1fr 1fr / repeat(4, 1fr);
  gap: 1rem;
}
.exchange-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.exchange-time {
  color: #afafaf;
  font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
}
.exchange-amount {
  color: #fff;
  font-size: clamp($text-16, 0.7857rem + 1.0714vw, $text-28);
  font-weight: 500;
  margin: 0.5rem 0 1.5rem 0;
}
.currencies {
  display: flex;
  gap: 1.5rem;
  margin: auto 0 0 0;
  & span {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #afafaf;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    font-weight: 400;
    & img {
      height: 24px;
      width: 24px;
      display: block;
    }
  }
}

// FAQ

#faq {
  & .container {
    display: flex;
    gap: 1.5rem;
    & .section-title {
      flex: 0 1 33%;
    }
  }
}
.faq {
  display: flex;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.accordion {
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.2s ease;
  &:hover {
    border: 1px solid $mainColor;
  }
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  align-self: stretch;
  font-size: clamp($text-16, 0.9286rem + 0.3571vw, $text-20);
  font-weight: 500;

  & i {
    transition: all 0.3s ease;
    &::before {
      font-size: 1.5rem;
    }
    &[aria-expanded='false'] {
      transform: rotate(0deg);
    }
    &[aria-expanded='true'] {
      transform: rotate(45deg) scale(1.15);
      color: #9c9c9c;
    }
  }
}

.accordion-content {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  & p {
    margin: 1rem 0 0 0;
    font-weight: 600;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    color: #bababa;
  }
}
.collapsed-questions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  max-height: 1000px;
  transition: all 1s ease;
  opacity: 1;
}

.collapsed-questions[aria-expanded='true'] {
  opacity: 0;
  max-height: 0px;
  margin: -1.25rem 0 0 0;
}

.accordion-content[aria-expanded='true'] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.show-more-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 24px;
  border: 1px solid #464646;
  color: #fff;
  font-size: clamp($text-16, 0.9286rem + 0.3571vw, $text-20);
  font-weight: 500;
  cursor: pointer;
  transition: border 0.2s ease;
  &:hover {
    border: 1px solid $mainColor;
  }
  & i {
    transition: all 0.5s ease 0.4s;
    transform: rotate(-90deg);
    &.down {
      transition: all 0.8s ease;
      transform: rotate(90deg);
    }
  }
}

// Media queries
@media (max-width: 1200px) {
  i.big-icon::before {
    font-size: max(120px, 15svw);
  }
}
@media (max-width: 1024px) {
  #benefits {
    margin: 0 0 3rem 0;
  }
  .benefits {
    flex-wrap: wrap;
  }
  .benefits-item {
    flex: 1 1 45%;
    aspect-ratio: unset;
    & i:first-child {
      margin: 0 0 2.5rem 0;
    }
    & i.big-icon {
      right: -8%;
      bottom: -17%;
    }
  }
  .exchange-item {
    flex: 1 1 30%;
  }
  .exchange-item:nth-child(7),
  .exchange-item:nth-child(8) {
    display: none;
  }
}
@media (max-width: 768px) {
  .exchanges {
    grid-template-rows: 1fr;
    overflow: auto;
    padding: 0.5rem 0;
  }
  .exchange-item {
    flex: 1 1 auto;
    min-width: max(223px, 40svw);
  }
  .exchange-item:nth-child(7),
  .exchange-item:nth-child(8) {
    display: flex;
  }
  #faq .container {
    flex-direction: column;
    & .section-title {
      flex: 1 1 auto;
      margin: 0;
    }
  }
  .faq,
  .collapsed-questions {
    gap: 1rem;
  }
}
@media (max-width: 560px) {
  .exchange-item {
    min-width: max(223px, 60svw);
  }
}
@media (max-width: 425px) {
  .benefits-item {
    padding: 1rem;
    flex: 1 1 100%;
  }
  .benefits-item i:first-child {
    margin: 0 0 1.5rem 0;
  }
}
@media (max-width: 320px) {
  .benefits-item i:first-child {
    margin: 0 0 2rem 0;
  }
}
