* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
.why-choose-us {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.why-choose-us .benefits-item {
  flex: 1 1 auto;
  aspect-ratio: 2/1;
}
.why-choose-us .benefits-item i {
  height: 39px;
  width: 36px;
}
.why-choose-us .benefits-item i::before {
  font-size: 36px;
  filter: drop-shadow(0 0 35px rgb(104, 255, 200));
}

.information {
  display: flex;
  gap: 1rem;
}

.information-item {
  flex: 1 1 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.information-item i {
  height: 39px;
  width: 36px;
}
.information-item i::before {
  font-size: 36px;
  filter: drop-shadow(0 0 35px rgb(104, 255, 200));
}
.information-item i::before {
  font-size: 36px;
}

.information-text {
  margin: 3rem 0 0 0;
}
.information-text h4 {
  font-weight: 700;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  color: #fff;
  margin: 0 0 1rem 0;
}
.information-text p {
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  color: #afafaf;
}

.how-to-exchange {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 5rem 0;
}
.how-to-exchange .title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 0 1 45%;
}
.how-to-exchange .title h3 {
  font-weight: 700;
  font-size: clamp(1.5rem, 1.2857rem + 1.0714vw, 2.25rem);
  color: #fff;
}
.how-to-exchange .title p {
  font-weight: 400;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  color: #b7b7b7;
}
.how-to-exchange .steps {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #464646;
  border-radius: 24px;
  padding: 24px;
  transition: border 0.3s ease;
}
.how-to-exchange .steps:hover {
  border: 1px solid #68ffc8;
}
.how-to-exchange .steps-title {
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  line-height: 175%;
  color: #afafaf;
}
.how-to-exchange .step {
  cursor: default;
  position: relative;
  border-radius: 24px;
  padding: 24px 24px 24px 76px;
  background: #242424;
  border: 1px solid transparent;
  transition: border 0.3s ease;
}
.how-to-exchange .step:hover {
  border: 1px solid #68ffc8;
}
.how-to-exchange .step span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  border-radius: 50%;
  background-color: #68ffc8;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  font-weight: 700;
}
.how-to-exchange .step:last-child {
  background-color: #68ffc8;
  color: #000;
  font-weight: bold;
}
.how-to-exchange .step:last-child span {
  background-color: #000;
  color: #fff;
}

@media (max-width: 1024px) {
  .why-choose-us .benefits-item {
    aspect-ratio: unset;
  }
  .why-choose-us .benefits-item i:first-child {
    margin: 0 0 1rem 0;
  }
  .information {
    flex-wrap: wrap;
  }
  .information-item {
    flex: 1 1 40%;
  }
  .information-text {
    margin: 1.5rem 0 0 0;
  }
  .information-text h4 {
    margin: 0 0 0.75rem 0;
  }
  .how-to-exchange .title {
    flex: 1 1 85%;
  }
  .how-to-exchange .step {
    padding: 1rem 1rem 1rem 4rem;
  }
}
@media (max-width: 768px) {
  .why-choose-us {
    flex-wrap: wrap;
  }
  .why-choose-us .benefits-item {
    flex: 1 1 30%;
  }
  .how-to-exchange {
    flex-direction: column;
    margin: 0 0 2rem 0;
  }
  .how-to-exchange .steps {
    align-self: stretch;
  }
  .tradingview-widget-container {
    min-height: 400px;
    max-height: 80svh;
  }
}
@media (max-width: 560px) {
  #widget .container {
    padding: 0 2.5rem 0 0;
  }
  .why-choose-us .benefits-item {
    flex: 1 1 100%;
  }
  .why-choose-us .benefits-item i:first-child {
    align-self: flex-end;
    margin: 0;
  }
  .information-item {
    flex: 1 1 100%;
  }
  .how-to-exchange .step span {
    height: 28px;
    width: 28px;
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .information-text {
    margin: 0.5rem 0 0 0;
  }
  .information-text h4 {
    margin: 0 0 0.25rem 0;
  }
  .how-to-exchange .step {
    padding: 1rem 0.5rem 1rem 3.5rem;
  }
}/*# sourceMappingURL=HowItsWork.css.map */