* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
header {
  z-index: 10;
  height: 96px;
}
header .container {
  display: flex;
  align-items: center;
  gap: 2.25rem;
  padding: 1.5rem 0;
  max-height: 100%;
}

.logo {
  max-height: 100%;
  max-width: 4rem;
  transition: filter 0.3s ease;
}
.logo:hover {
  filter: drop-shadow(0 0 2px rgba(104, 255, 200, 0.7));
}

.menu-list {
  display: flex;
  gap: 1rem;
}

.menu-link {
  display: block;
  padding: 0.75rem 1.5rem;
  color: #CDCDCD;
  font-weight: normal;
  transition: color 0.4s;
  font-size: 1rem;
}
.menu-link:hover, .menu-link.active {
  color: #FFF;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.buttons-wrapper > * {
  align-self: center;
}
.buttons-wrapper .active {
  filter: drop-shadow(0 0 25px rgba(104, 255, 200, 0.4));
}

.__select {
  height: 100%;
  position: relative;
}
.__select::before {
  content: url("/src/images/arrow-down-s-fill.svg");
  position: absolute;
  top: calc(50% - 12px);
  transform: rotateX(0deg);
  right: 16px;
  z-index: 2;
  pointer-events: none;
  transition: all 0.4s ease;
  display: block;
  width: 24px;
  height: 24px;
}
.__select.show {
  background-color: #242424;
  border-radius: 50px;
}
.__select.show::before {
  transform: rotateX(180deg);
}
.__select.show .__select__content {
  opacity: 1;
  pointer-events: all;
  top: 25px;
}

.__select__title {
  display: flex;
  padding: 12px 52px 12px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 50px;
  background: #242424;
  box-shadow: 0px 2px 67.4px -1px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.__select__content {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 8px 12px 8px;
  background-color: inherit;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: opacity 0.1s ease-out, all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
}

.__select__input {
  display: none;
}

.__select__label {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 16px;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.burger-menu {
  display: flex;
}
.burger-menu > input {
  display: none;
}
.burger-menu #burger + label {
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 20px;
  height: 24px;
  width: 24px;
  z-index: 5;
  display: block;
  z-index: 11;
}
.burger-menu #burger + label span {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  left: 0;
  display: block;
  background: #FFF;
  transition: 0.5s;
}
.burger-menu #burger + label span:first-child {
  top: 2px;
}
.burger-menu #burger + label span:last-child {
  top: calc(100% - 2px);
}
.burger-menu #burger:checked + label + nav + .buttons-wrapper {
  opacity: 1;
  pointer-events: all;
}
.burger-menu #burger:checked + label span {
  opacity: 0;
  top: 50%;
  background: #FFF;
}
.burger-menu #burger:checked + label span:first-child {
  opacity: 1;
  transform: rotate(405deg);
}
.burger-menu #burger:checked + label span:last-child {
  opacity: 1;
  transform: rotate(-405deg);
}
.burger-menu nav {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transition: 0.5s;
  transition-delay: 0.5s;
  overflow: hidden;
  background-color: transparent;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.burger-menu nav > ul {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.burger-menu nav > ul > li {
  opacity: 0;
  transition: 0.5s;
  transition-delay: 0s;
}
.burger-menu nav > ul > li > a {
  font-size: clamp(1.5rem, 1.0714rem + 2.1429vw, 3rem);
  text-decoration: none;
  text-transform: uppercase;
  color: #FFF;
  font-weight: bold;
}
.burger-menu nav > ul > li > a.active {
  color: #68ffc8;
}
.burger-menu #burger:checked ~ nav {
  pointer-events: all;
  height: 100%;
  transition-delay: 0s;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 10;
}
.burger-menu #burger:checked ~ nav > ul > li {
  opacity: 1;
  transition-delay: 0.5s;
}
.burger-menu .buttons-wrapper {
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  position: fixed;
  bottom: 1rem;
  left: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100svw;
  transition: all 0.5s ease;
}
.burger-menu .buttons-wrapper form {
  position: fixed;
  top: 34px;
}
.burger-menu .buttons-wrapper a {
  background: transparent;
  padding: 12px 24px 12px 12px;
  border: 2px solid #FFF;
  color: #FFF;
}
.burger-menu .buttons-wrapper a i:before {
  color: #FFF;
}

@media (min-width: 1025px) and (max-width: 1200px) {
  header .container {
    gap: 1rem;
  }
  .buttons-wrapper .btn {
    padding: 12px 16px 12px 8px;
    gap: 0.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1070px) {
  .menu-link {
    padding: 0.75rem 1rem;
  }
}
@media (max-width: 1024px) {
  .buttons-wrapper {
    margin: 0 1.5rem 0 0;
  }
  header .container {
    gap: 1rem;
  }
}
@media (min-width: 560px) and (max-width: 630px) {
  .buttons-wrapper {
    gap: 0.5rem;
  }
  .buttons-wrapper .btn {
    padding: 12px 18px 12px 12px;
  }
}
#footer {
  position: relative;
  background: #242424;
  margin: 1.5rem 0 0 0;
}
#footer::before, #footer::after {
  content: "";
  display: block;
  position: absolute;
  transform: translateY(calc(-100% + 1px));
  width: 100svw;
  height: 24px;
  width: 24px;
  top: 0;
  background-color: #242424;
}
#footer::before {
  left: 0;
  -webkit-clip-path: polygon(0% 0%, 0% 99%, 99% 99%, 87% 94%, 78% 91%, 68% 86%, 58% 81%, 47% 74%, 34% 64%, 21% 49%, 13% 33%, 7% 18%);
          clip-path: polygon(0% 0%, 0% 99%, 99% 99%, 87% 94%, 78% 91%, 68% 86%, 58% 81%, 47% 74%, 34% 64%, 21% 49%, 13% 33%, 7% 18%);
}
#footer::after {
  right: 0;
  -webkit-clip-path: polygon(100% 0%, 100% 99%, 1% 99%, 13% 94%, 22% 91%, 32% 86%, 42% 81%, 53% 74%, 66% 64%, 79% 49%, 87% 33%, 93% 18%);
          clip-path: polygon(100% 0%, 100% 99%, 1% 99%, 13% 94%, 22% 91%, 32% 86%, 42% 81%, 53% 74%, 66% 64%, 79% 49%, 87% 33%, 93% 18%);
}
#footer .container {
  padding: 3rem 1rem 3rem 1rem;
}

.footer {
  display: flex;
  gap: 2rem 1rem;
  flex-wrap: wrap;
}

.footer-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 20%;
}
.footer-item:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-item .footer-heading {
  color: #FFF;
  font-size: 1rem;
  font-weight: 700;
  line-height: 131.25%; /* 21px */
  text-transform: capitalize;
}
.footer-item p {
  display: block;
  max-width: clamp(160px, 12vw, 100%);
  color: #FFF;
  font-size: 1rem;
  font-weight: 400;
  line-height: 137.5%; /* 22px */
}

.footer-logo {
  width: 44px;
  height: 44px;
}

.footer-list li a {
  display: block;
  padding: 0.35rem 0.35rem 0.35rem 0;
  color: #BBB;
  font-size: 0.875rem;
  letter-spacing: 0.14px;
  transition: all 0.3s ease-in-out;
}
.footer-list li a:hover {
  color: #FFF;
}

.social {
  display: flex;
  gap: 0.5rem;
}
.social li a {
  display: block;
  aspect-ratio: 1/1;
  padding: 0.75rem;
  border-radius: 12px;
  border: 1px solid #464646;
  transition: all 0.3s ease-in-out;
}
.social li a:hover {
  background-color: #464646;
}
.social li a i {
  color: #FFF;
}

@media (max-width: 768px) {
  .footer-item {
    flex: 1 1 40%;
  }
}
@media (max-width: 560px) {
  .footer-item {
    flex: 1 1 100%;
  }
}
@media (max-width: 400px) {
  .footer-item {
    gap: 0.5rem;
  }
}
#benefits {
  margin: 0 0 8rem 0;
}

.benefits {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.benefits-item {
  position: relative;
  display: flex;
  padding: 24px;
  aspect-ratio: 1/1;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 1 25%;
  border-radius: 24px;
  background: #242424;
  background: linear-gradient(135deg, #242424 0%, #242424 52.6%, rgba(36, 36, 36, 0.61) 80.5%, rgba(36, 36, 36, 0) 100%);
}
.benefits-item i::before {
  filter: drop-shadow(0 0 35px rgb(104, 255, 200));
}

i.big-icon {
  position: absolute;
  right: -13%;
  bottom: -18%;
  transform: rotate(-15deg);
}
i.big-icon::before {
  filter: none;
  position: relative;
  font-size: 200px;
  background-size: 100%;
  background: radial-gradient(circle farthest-corner at top left, #242424 6%, #0d0d0d 75%);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: -1;
}

.benefits-text {
  position: relative;
  z-index: 1;
}
.benefits-text h4 {
  font-size: clamp(1rem, 0.8929rem + 0.5357vw, 1.375rem);
  font-weight: 500;
  line-height: 163.63%; /* 36px */
}
.benefits-text p {
  color: #afafaf;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  font-weight: 400;
  line-height: 150%;
}

#exchanges .container {
  cursor: default;
}

.exchanges {
  display: grid;
  grid-template: 1fr 1fr/repeat(4, 1fr);
  gap: 1rem;
}

.exchange-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.exchange-time {
  color: #afafaf;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
}

.exchange-amount {
  color: #fff;
  font-size: clamp(1rem, 0.7857rem + 1.0714vw, 1.75rem);
  font-weight: 500;
  margin: 0.5rem 0 1.5rem 0;
}

.currencies {
  display: flex;
  gap: 1.5rem;
  margin: auto 0 0 0;
}
.currencies span {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #afafaf;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  font-weight: 400;
}
.currencies span img {
  height: 24px;
  width: 24px;
  display: block;
}

#faq .container {
  display: flex;
  gap: 1.5rem;
}
#faq .container .section-title {
  flex: 0 1 33%;
}

.faq {
  display: flex;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.accordion {
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.2s ease;
}
.accordion:hover {
  border: 1px solid #68ffc8;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  align-self: stretch;
  font-size: clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem);
  font-weight: 500;
}
.accordion-title i {
  transition: all 0.3s ease;
}
.accordion-title i::before {
  font-size: 1.5rem;
}
.accordion-title i[aria-expanded=false] {
  transform: rotate(0deg);
}
.accordion-title i[aria-expanded=true] {
  transform: rotate(45deg) scale(1.15);
  color: #9c9c9c;
}

.accordion-content {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.accordion-content p {
  margin: 1rem 0 0 0;
  font-weight: 600;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  color: #bababa;
}

.collapsed-questions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  max-height: 1000px;
  transition: all 1s ease;
  opacity: 1;
}

.collapsed-questions[aria-expanded=true] {
  opacity: 0;
  max-height: 0px;
  margin: -1.25rem 0 0 0;
}

.accordion-content[aria-expanded=true] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.show-more-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 24px;
  border: 1px solid #464646;
  color: #fff;
  font-size: clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem);
  font-weight: 500;
  cursor: pointer;
  transition: border 0.2s ease;
}
.show-more-btn:hover {
  border: 1px solid #68ffc8;
}
.show-more-btn i {
  transition: all 0.5s ease 0.4s;
  transform: rotate(-90deg);
}
.show-more-btn i.down {
  transition: all 0.8s ease;
  transform: rotate(90deg);
}

@media (max-width: 1200px) {
  i.big-icon::before {
    font-size: max(120px, 15svw);
  }
}
@media (max-width: 1024px) {
  #benefits {
    margin: 0 0 3rem 0;
  }
  .benefits {
    flex-wrap: wrap;
  }
  .benefits-item {
    flex: 1 1 45%;
    aspect-ratio: unset;
  }
  .benefits-item i:first-child {
    margin: 0 0 2.5rem 0;
  }
  .benefits-item i.big-icon {
    right: -8%;
    bottom: -17%;
  }
  .exchange-item {
    flex: 1 1 30%;
  }
  .exchange-item:nth-child(7),
  .exchange-item:nth-child(8) {
    display: none;
  }
}
@media (max-width: 768px) {
  .exchanges {
    grid-template-rows: 1fr;
    overflow: auto;
    padding: 0.5rem 0;
  }
  .exchange-item {
    flex: 1 1 auto;
    min-width: max(223px, 40svw);
  }
  .exchange-item:nth-child(7),
  .exchange-item:nth-child(8) {
    display: flex;
  }
  #faq .container {
    flex-direction: column;
  }
  #faq .container .section-title {
    flex: 1 1 auto;
    margin: 0;
  }
  .faq,
  .collapsed-questions {
    gap: 1rem;
  }
}
@media (max-width: 560px) {
  .exchange-item {
    min-width: max(223px, 60svw);
  }
}
@media (max-width: 425px) {
  .benefits-item {
    padding: 1rem;
    flex: 1 1 100%;
  }
  .benefits-item i:first-child {
    margin: 0 0 1.5rem 0;
  }
}
@media (max-width: 320px) {
  .benefits-item i:first-child {
    margin: 0 0 2rem 0;
  }
}/*# sourceMappingURL=Home.css.map */