* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
.steps {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.steps-item {
  aspect-ratio: 1.2/1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}
.steps-item:last-child {
  background: #68ffc8;
  color: #171717;
}
.steps-item:last-child p span {
  color: #68ffc8;
}
.steps-item:last-child h4 + span {
  color: #000;
}
.steps-item p {
  flex: 1 1 auto;
}
.steps-item p span {
  display: flex;
  width: 62px;
  height: 62px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #171717;
  font-size: clamp(1rem, 0.6429rem + 1.7857vw, 2.25rem);
  font-weight: 700;
  color: #FFF;
}
.steps-item h4 {
  font-size: clamp(1rem, 0.6429rem + 1.7857vw, 2.25rem);
  font-weight: 700;
}
.steps-item span {
  color: #AFAFAF;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  font-weight: 400;
}

@media (max-width: 1200px) {
  .steps-item {
    aspect-ratio: unset;
  }
}
@media (max-width: 768px) {
  .steps {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
  }
}
@media (max-width: 400px) {
  .steps {
    grid-template-rows: repeat(3, auto);
  }
  .steps-item p span {
    width: 48px;
    height: 48px;
  }
}/*# sourceMappingURL=steps.css.map */