@import '../../sass/base/_global.scss';
.about-image {
  position: relative;
  min-height: 80px;
  & h1 {
    position: absolute;
    top: 1.25rem;
    left: 3rem;
    font-size: clamp($text-24, 0.7857rem + 3.5714vw, $text-64);
  }
  & img {
    border-radius: 24px;
    min-height: 80px;
  }
}

.about-text {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  & h2 {
    font-size: clamp($text-20, 1.1786rem + 0.3571vw, $text-24);
    &.bigger {
      font-size: clamp($text-28, 1.6071rem + 0.7143vw, $text-36);
    }
  }
  & p {
    margin: 1rem 0 2rem 0;
    color: #bebebe;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    line-height: 1.5;
  }
}
.default-list {
  list-style: disc;
  padding: 0 0 2rem 1.5rem;
  & li {
    margin: 0.5rem 0 0.5rem 0;
    color: #bebebe;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    line-height: 1.5;
  }
}
.literal-list {
  margin: -1rem 0 2rem 0;
  & li {
    margin: 0.25rem 0;
    color: #bebebe;
    line-height: 1.5;
  }
}

.link {
  color: $mainColor;
  text-decoration: underline;
}
.about-currencies {
  & h2 {
    font-weight: bold;
    font-size: clamp($text-32, 1.9286rem + 0.3571vw, $text-36);
    letter-spacing: 0.01em;
    color: #fff;
    margin: 0 0 1rem 0;
  }
  & a.btn {
    font-weight: bold;
    margin: 3rem 0 1.5rem 0;
  }
}

.currencies {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  & .currency-item {
    --x-px: calc(var(--x) * 1px);
    --y-px: calc(var(--y) * 1px);
    overflow: hidden;
    border-radius: 24px;
    min-width: 20%;
    flex: 1 1 20%;
    min-height: 150px;
    position: relative;
    &__inset {
      border-radius: inherit;
      z-index: 3;
      inset: 2px;
      position: absolute;
      padding: 16px;
      background: #242424;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.5rem;
    }
    &:hover:after {
      opacity: 1;
    }
    &:before {
      z-index: 1;
    }
    &:after {
      opacity: 0;
      z-index: 2;
      transition: opacity 0.4s ease;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      inset: 0px;
      border-radius: inherit;
      background: radial-gradient(300px circle at var(--x-px) var(--y-px), $mainColor, transparent 50%);
    }

    & img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
    & h3 {
      font-weight: bold;
      font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    }
  }
}

@media (max-width: 1024px) {
  #about {
    padding: 1.5rem 0 0;
  }
  .about-text {
    padding: 2rem 0 0;
  }
  .about-text p {
    margin: 0 0 1.5rem 0;
  }
}
@media (max-width: 768px) {
  .about-image h1 {
    left: 2rem;
  }
  .currencies .currency-item {
    flex: 0 1 30%;
  }
}

@media (max-width: 560px) {
  .about-image h1 {
    left: 1rem;
  }
  .currencies .currency-item {
    flex: 1 1 40%;
  }
  .currencies .currency-item__inset {
    gap: 0.5rem;
  }
}
