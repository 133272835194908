@import '../../../sass/base/_global.scss';

#partnership-hero {
  position: relative;
  padding: 3rem 0;
  min-height: calc(100svh - 96px);
  & .container {
    display: flex;
    gap: 1.5rem 1rem;
    height: 100%;
    & .hero-text {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & h1 {
        font-size: clamp($text-32, 1.7143rem + 1.4286vw, $text-48);
      }
      & h3 {
        font-weight: 700;
        font-size: clamp($text-32, 1.7143rem + 1.4286vw, $text-48);
        color: #fff;
      }
      & h4 {
        font-weight: 400;
        font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
        line-height: 150%;
        color: #bebebe;
      }
    }
  }
}

.hero-blocks {
  align-self: center;
  flex: 0 1 50%;
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  gap: 1rem;
  & .block {
    position: relative;
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/1;
    border-radius: 24px;
    padding: 24px;
    background: #242424;
    overflow: hidden;
    & i:first-child {
      @include glowingIcon;
    }
    & h3 {
      position: relative;
      z-index: 0;
      font-weight: 700;
      font-size: clamp($text-14, 0.7679rem + 0.5357vw, $text-20);
      line-height: 164%;
      color: #fff;
      margin-top: auto;
    }
    &:last-child {
      padding: 0;
      overflow: hidden;
      background-image: url('../../../images/partners-links.png');
      background-size: 200%;
      background-position: center center;
      background-repeat: no-repeat;
      & img {
        display: block;
        width: 110%;
        height: 110%;
        object-fit: cover;
        transform: translate(-3%, -1%) scale(1.5);
      }
    }
  }
}

.why-us {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
  & .block:first-child {
    flex: 1 1 30%;
    min-height: 217px;
    display: flex;
    align-items: flex-end;
  }
  & .block:not(:first-child) {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 30%;
    min-height: 217px;
    align-self: stretch;
    border-radius: 24px;
    padding: 24px;
    background: #242424;
    & i {
      @include glowingIcon;
    }
    & b {
      position: absolute;
      font-size: clamp($text-18, 0.875rem + 1.25vw, $text-32);
      color: #8a8a8a;
      right: 1.5rem;
      top: 1rem;
    }
    & h3 {
      display: block;
      font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
      color: #fff;
      margin: auto 0 0.5rem 0;
    }
    & span {
      font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
      color: #afafaf;
    }
  }
}

#contact-us .container {
  display: flex;
  justify-content: space-between;

  & .section-title {
    flex: 0 1 50%;
  }
}
.contact-us {
  display: flex;
  gap: 1rem;
  flex: 1 1 auto;
  & .contact-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 200px;
    border-radius: 24px;
    padding: 24px;
    background: #242424;
    cursor: pointer;
    transition: border 0.3s ease;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $mainColor;
      & i[class='ri-arrow-right-line'] {
        color: $mainColor;
      }
    }
    & i:not([class='ri-arrow-right-line']) {
      @include glowingIcon;
    }
    & i[class='ri-arrow-right-line'] {
      transition: color 0.3s ease;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      &::before {
        font-size: 24px;
      }
    }
    & h3 {
      margin-top: auto;
      font-weight: bold;
      font-size: clamp($text-18, 1.0179rem + 0.5357vw, $text-24);
    }
  }
}

@media (max-width: 1024px) {
  .hero-blocks .block {
    aspect-ratio: unset;
  }
}
@media (max-width: 768px) {
  #partnership-hero {
    padding: 1rem 0;
  }
  #partnership-hero .container {
    flex-direction: column;
  }
  .hero-blocks .block:last-child {
    background-size: 120%;
    background-position: 55% 65%;
  }
  #contact-us .container {
    flex-direction: column;
  }
}
@media (max-width: 560px) {
  .hero-blocks .block {
    padding: 1rem;
  }
  .hero-blocks .block:last-child {
    background-size: 200%;
    background-position: 55% 55%;
  }
  .why-us .block:first-child {
    min-height: auto;
  }
  .why-us .block:not(:first-child) {
    min-height: auto;
    & h3 {
      margin: 1rem 0 0.5rem 0;
    }
  }
}
@media (max-width: 400px) {
  .contact-us .contact-item {
    min-height: auto;
    & h3 {
      margin: 1rem 0 0 0;
    }
  }
}
@media (max-width: 320px) {
  .why-us {
    flex-direction: column;
  }
}
