@import '../../../sass/base/_global.scss';

.why-choose-us {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  & .benefits-item {
    flex: 1 1 auto;
    aspect-ratio: 2/1;
    & i {
      @include glowingIcon;
    }
  }
}

.information {
  display: flex;
  gap: 1rem;
}
.information-item {
  flex: 1 1 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & i {
    @include glowingIcon;
    &::before {
      font-size: 36px;
    }
  }
}
.information-text {
  margin: 3rem 0 0 0;
  & h4 {
    font-weight: 700;
    font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
    color: #fff;
    margin: 0 0 1rem 0;
  }

  & p {
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    color: #afafaf;
  }
}

.how-to-exchange {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 5rem 0;
  & .title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 0 1 45%;
    & h3 {
      font-weight: 700;
      font-size: clamp($text-24, 1.2857rem + 1.0714vw, $text-36);
      color: #fff;
    }
    & p {
      font-weight: 400;
      font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
      color: #b7b7b7;
    }
  }
  & .steps {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #464646;
    border-radius: 24px;
    padding: 24px;
    transition: border 0.3s ease;
    &:hover {
      border: 1px solid $mainColor;
    }
  }
  & .steps-title {
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    line-height: 175%;
    color: #afafaf;
  }
  & .step {
    cursor: default;
    position: relative;
    border-radius: 24px;
    padding: 24px 24px 24px 76px;
    background: #242424;
    border: 1px solid transparent;
    transition: border 0.3s ease;
    &:hover {
      border: 1px solid $mainColor;
    }
    & span {
      @include centeredPseudoElement;
      left: 1rem;
      border-radius: 50%;
      background-color: $mainColor;
      color: #242424;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      font-weight: 700;
    }
    &:last-child {
      background-color: $mainColor;
      color: #000;
      font-weight: bold;
      & span {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

@media (max-width: 1024px) {
  .why-choose-us .benefits-item {
    aspect-ratio: unset;
    & i:first-child {
      margin: 0 0 1rem 0;
    }
  }
  .information {
    flex-wrap: wrap;
    &-item {
      flex: 1 1 40%;
    }
  }
  .information-text {
    margin: 1.5rem 0 0 0;
    & h4 {
      margin: 0 0 0.75rem 0;
    }
  }
  .how-to-exchange .title {
    flex: 1 1 85%;
  }
  .how-to-exchange .step {
    padding: 1rem 1rem 1rem 4rem;
  }
}
@media (max-width: 768px) {
  .why-choose-us {
    flex-wrap: wrap;
    & .benefits-item {
      flex: 1 1 30%;
    }
  }
  .how-to-exchange {
    flex-direction: column;
    margin: 0 0 2rem 0;
    & .steps {
      align-self: stretch;
    }
  }
  .tradingview-widget-container {
    min-height: 400px;
    max-height: 80svh;
  }
}
@media (max-width: 560px) {
  #widget .container {
    padding: 0 2.5rem 0 0;
  }
  .why-choose-us .benefits-item {
    flex: 1 1 100%;
    & i:first-child {
      align-self: flex-end;
      margin: 0;
    }
  }
  .information-item {
    flex: 1 1 100%;
  }
  .how-to-exchange .step span {
    height: 28px;
    width: 28px;
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .information-text {
    margin: 0.5rem 0 0 0;
    & h4 {
      margin: 0 0 0.25rem 0;
    }
  }
  .how-to-exchange .step {
    padding: 1rem 0.5rem 1rem 3.5rem;
  }
}
