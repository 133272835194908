@import '../../sass/base/_global.scss';

#hero {
  position: relative;
  overflow: visible;
  padding: 3rem 0;
  min-height: calc(100svh - 96px);
  margin: 0;
  & .container {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem 1rem;
    height: 100%;
  }
}
.hero-bg {
  position: fixed;
  left: -5svw;
  top: -20px;
  width: 110svw;
  z-index: -1;
}
.hero-text {
  flex: 0 1 46%;
  & h1 {
    color: #fff;
    font-size: clamp($text-32, 1.4286rem + 2.8571vw, $text-64);
    font-weight: 700;
    letter-spacing: 0.64px;
    margin: 0 0 1rem 0;
    word-wrap: break-word;
  }
  & h2 {
    color: #b7b7b7;
    font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
    font-weight: 400;
  }
}
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #464646;
  transition: border 0.2s ease;
  &.focused {
    border: 1px solid $mainColor;
  }
}
.input {
  display: flex;
  padding: 16px 16px 16px 20px;
  align-self: stretch;
  width: 100%;
  color: #fff;
  font-size: clamp($text-14, 0.7679rem + 0.5357vw, $text-20);
  font-weight: 500;
  &::placeholder {
    color: #cfcfcf;
  }
}

@media screen and (min-height: 920px) {
  #hero {
    min-height: fit-content;
    max-height: fit-content;
  }
}

@media (max-width: 1070px) {
  #hero {
    min-height: fit-content;
    margin: 0 0 3rem 0;
  }
  #hero .container {
    flex-direction: column;
  }
  .hero-exchange {
    flex: 1 1 auto;
  }
  .hero-bg {
    top: 50px;
  }
}

@media (max-width: 1024px) {
  #hero {
    padding: 1rem 0;
  }

  .steps-item {
    aspect-ratio: unset;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 560px) {
}

@media (max-width: 400px) {
}
