* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
#partnership-hero {
  position: relative;
  padding: 3rem 0;
  min-height: calc(100svh - 96px);
}
#partnership-hero .container {
  display: flex;
  gap: 1.5rem 1rem;
  height: 100%;
}
#partnership-hero .container .hero-text {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#partnership-hero .container .hero-text h1 {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
}
#partnership-hero .container .hero-text h3 {
  font-weight: 700;
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  color: #fff;
}
#partnership-hero .container .hero-text h4 {
  font-weight: 400;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  line-height: 150%;
  color: #bebebe;
}

.hero-blocks {
  align-self: center;
  flex: 0 1 50%;
  display: grid;
  grid-template: 1fr 1fr/1fr 1fr;
  gap: 1rem;
}
.hero-blocks .block {
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1/1;
  border-radius: 24px;
  padding: 24px;
  background: #242424;
  overflow: hidden;
}
.hero-blocks .block i:first-child {
  height: 39px;
  width: 36px;
}
.hero-blocks .block i:first-child::before {
  font-size: 36px;
  filter: drop-shadow(0 0 35px rgb(104, 255, 200));
}
.hero-blocks .block h3 {
  position: relative;
  z-index: 0;
  font-weight: 700;
  font-size: clamp(0.875rem, 0.7679rem + 0.5357vw, 1.25rem);
  line-height: 164%;
  color: #fff;
  margin-top: auto;
}
.hero-blocks .block:last-child {
  padding: 0;
  overflow: hidden;
  background-image: url("../../../images/partners-links.png");
  background-size: 200%;
  background-position: center center;
  background-repeat: no-repeat;
}
.hero-blocks .block:last-child img {
  display: block;
  width: 110%;
  height: 110%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: translate(-3%, -1%) scale(1.5);
}

.why-us {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
}
.why-us .block:first-child {
  flex: 1 1 30%;
  min-height: 217px;
  display: flex;
  align-items: flex-end;
}
.why-us .block:not(:first-child) {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 30%;
  min-height: 217px;
  align-self: stretch;
  border-radius: 24px;
  padding: 24px;
  background: #242424;
}
.why-us .block:not(:first-child) i {
  height: 39px;
  width: 36px;
}
.why-us .block:not(:first-child) i::before {
  font-size: 36px;
  filter: drop-shadow(0 0 35px rgb(104, 255, 200));
}
.why-us .block:not(:first-child) b {
  position: absolute;
  font-size: clamp(1.125rem, 0.875rem + 1.25vw, 2rem);
  color: #8a8a8a;
  right: 1.5rem;
  top: 1rem;
}
.why-us .block:not(:first-child) h3 {
  display: block;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  color: #fff;
  margin: auto 0 0.5rem 0;
}
.why-us .block:not(:first-child) span {
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  color: #afafaf;
}

#contact-us .container {
  display: flex;
  justify-content: space-between;
}
#contact-us .container .section-title {
  flex: 0 1 50%;
}

.contact-us {
  display: flex;
  gap: 1rem;
  flex: 1 1 auto;
}
.contact-us .contact-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 200px;
  border-radius: 24px;
  padding: 24px;
  background: #242424;
  cursor: pointer;
  transition: border 0.3s ease;
  border: 1px solid transparent;
}
.contact-us .contact-item:hover {
  border: 1px solid #68ffc8;
}
.contact-us .contact-item:hover i[class=ri-arrow-right-line] {
  color: #68ffc8;
}
.contact-us .contact-item i:not([class=ri-arrow-right-line]) {
  height: 39px;
  width: 36px;
}
.contact-us .contact-item i:not([class=ri-arrow-right-line])::before {
  font-size: 36px;
  filter: drop-shadow(0 0 35px rgb(104, 255, 200));
}
.contact-us .contact-item i[class=ri-arrow-right-line] {
  transition: color 0.3s ease;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.contact-us .contact-item i[class=ri-arrow-right-line]::before {
  font-size: 24px;
}
.contact-us .contact-item h3 {
  margin-top: auto;
  font-weight: bold;
  font-size: clamp(1.125rem, 1.0179rem + 0.5357vw, 1.5rem);
}

@media (max-width: 1024px) {
  .hero-blocks .block {
    aspect-ratio: unset;
  }
}
@media (max-width: 768px) {
  #partnership-hero {
    padding: 1rem 0;
  }
  #partnership-hero .container {
    flex-direction: column;
  }
  .hero-blocks .block:last-child {
    background-size: 120%;
    background-position: 55% 65%;
  }
  #contact-us .container {
    flex-direction: column;
  }
}
@media (max-width: 560px) {
  .hero-blocks .block {
    padding: 1rem;
  }
  .hero-blocks .block:last-child {
    background-size: 200%;
    background-position: 55% 55%;
  }
  .why-us .block:first-child {
    min-height: auto;
  }
  .why-us .block:not(:first-child) {
    min-height: auto;
  }
  .why-us .block:not(:first-child) h3 {
    margin: 1rem 0 0.5rem 0;
  }
}
@media (max-width: 400px) {
  .contact-us .contact-item {
    min-height: auto;
  }
  .contact-us .contact-item h3 {
    margin: 1rem 0 0 0;
  }
}
@media (max-width: 320px) {
  .why-us {
    flex-direction: column;
  }
}/*# sourceMappingURL=partnership.css.map */