* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
.about-image {
  position: relative;
  min-height: 80px;
}
.about-image h1 {
  position: absolute;
  top: 1.25rem;
  left: 3rem;
  font-size: clamp(1.5rem, 0.7857rem + 3.5714vw, 4rem);
}
.about-image img {
  border-radius: 24px;
  min-height: 80px;
}

.about-text {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
}
.about-text h2 {
  font-size: clamp(1.25rem, 1.1786rem + 0.3571vw, 1.5rem);
}
.about-text h2.bigger {
  font-size: clamp(1.75rem, 1.6071rem + 0.7143vw, 2.25rem);
}
.about-text p {
  margin: 1rem 0 2rem 0;
  color: #bebebe;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  line-height: 1.5;
}

.default-list {
  list-style: disc;
  padding: 0 0 2rem 1.5rem;
}
.default-list li {
  margin: 0.5rem 0 0.5rem 0;
  color: #bebebe;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  line-height: 1.5;
}

.literal-list {
  margin: -1rem 0 2rem 0;
}
.literal-list li {
  margin: 0.25rem 0;
  color: #bebebe;
  line-height: 1.5;
}

.link {
  color: #68ffc8;
  text-decoration: underline;
}

.about-currencies h2 {
  font-weight: bold;
  font-size: clamp(2rem, 1.9286rem + 0.3571vw, 2.25rem);
  letter-spacing: 0.01em;
  color: #fff;
  margin: 0 0 1rem 0;
}
.about-currencies a.btn {
  font-weight: bold;
  margin: 3rem 0 1.5rem 0;
}

.currencies {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}
.currencies .currency-item {
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  overflow: hidden;
  border-radius: 24px;
  min-width: 20%;
  flex: 1 1 20%;
  min-height: 150px;
  position: relative;
}
.currencies .currency-item__inset {
  border-radius: inherit;
  z-index: 3;
  inset: 2px;
  position: absolute;
  padding: 16px;
  background: #242424;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
}
.currencies .currency-item:hover:after {
  opacity: 1;
}
.currencies .currency-item:before {
  z-index: 1;
}
.currencies .currency-item:after {
  opacity: 0;
  z-index: 2;
  transition: opacity 0.4s ease;
}
.currencies .currency-item:before, .currencies .currency-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  inset: 0px;
  border-radius: inherit;
  background: radial-gradient(300px circle at var(--x-px) var(--y-px), #68ffc8, transparent 50%);
}
.currencies .currency-item img {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
}
.currencies .currency-item h3 {
  font-weight: bold;
  font-size: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
}

@media (max-width: 1024px) {
  #about {
    padding: 1.5rem 0 0;
  }
  .about-text {
    padding: 2rem 0 0;
  }
  .about-text p {
    margin: 0 0 1.5rem 0;
  }
}
@media (max-width: 768px) {
  .about-image h1 {
    left: 2rem;
  }
  .currencies .currency-item {
    flex: 0 1 30%;
  }
}
@media (max-width: 560px) {
  .about-image h1 {
    left: 1rem;
  }
  .currencies .currency-item {
    flex: 1 1 40%;
  }
  .currencies .currency-item__inset {
    gap: 0.5rem;
  }
}/*# sourceMappingURL=about.css.map */