@import '../../../sass/base/global';
//Exchange page
#exchange {
  padding: 3rem 0 1rem 0;
  & .hero-exchange {
    flex: 0 1 50%;
  }
}
#step1 .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0;
  margin: 0;
}

.exchange-steps {
  display: flex;
  align-items: center;
  border: 1px solid #464646;
  border-radius: 100px;
  height: 60px;
  overflow: hidden;
  margin: 0 0 2rem 0;
  transition: all 0.3s ease;
  &.final {
    border-radius: 24px;
    padding: 0 1.5rem;
    height: 92px;
    background-color: $mainColor;
  }
  &__item {
    padding: 5px 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 0 1 33%;
    transition: all 0.5s ease;
    &.current {
      background: #68ffc8;
      & p {
        font-weight: 700;
        color: #000;
      }
    }
    &.ex-current {
      background: #68ffc8;
      & > * {
        opacity: 0.5;
      }
      & p {
        color: #000;
      }
    }
    &.final {
      padding: 0;
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      & p {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: clamp($text-16, 0.6429rem + 1.7857vw, $text-36);
        color: #000;
        gap: 1.5rem;
        & i::before {
          font-size: 30px;
        }
      }
    }
    & span {
      display: flex;
      width: 36px;
      height: 36px;
      flex: 0 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #171717;
      font-weight: 700;
      font-size: $text-16;
      color: #fff;
    }
    & p {
      font-weight: 400;
      font-size: $text-16;
      color: #b1b1b1;
    }
  }
}
.user-data {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.25rem;
  height: fit-content;
  & span {
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    color: #cfcfcf;
  }
}
.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  & .error {
    position: static;
  }
}
.user-input {
  & label {
    font-weight: 700;
    font-size: $text-16;
    display: block;
    margin: 0 0 0.75rem 0;
  }
  &__checkbox {
    display: flex;
    align-items: flex-start;
    position: relative;
    input[type='checkbox'] {
      cursor: pointer;
      width: 18px;
      height: 18px;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 3px;
      z-index: 1;
    }
    input[type='checkbox'] + label {
      position: relative;
      margin: 0;
    }
    input[type='checkbox'] + label > span {
      flex: 0 0 18px;
      position: relative;
      width: 18px;
      height: 18px;
      margin: 3px 0 0 0;
      background: transparent;
      border: 2px solid #464646;
      border-radius: 2px;
      cursor: pointer;
      transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    }
    input[type='checkbox'] + label:hover > span,
    input[type='checkbox']:focus + label > span,
    input[type='checkbox']:hover + label > span {
      border-color: $mainColor;
    }
    input[type='checkbox']:checked + label > span {
      border: 0.6em solid $mainColor;
      animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
    }
    input[type='checkbox']:checked + label > span:before {
      content: '';
      position: absolute;
      top: -2px;
      left: -5px;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      transform: rotate(45deg);
      transform-origin: 0% 100%;
      animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
    }
    @keyframes shrink-bounce {
      0% {
        transform: scale(1);
      }
      33% {
        transform: scale(0.85);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes checkbox-check {
      0% {
        width: 0;
        height: 0;
        border-color: #212121;
        transform: translate3d(0, 0, 0) rotate(45deg);
      }
      33% {
        width: 0.2em;
        height: 0;
        transform: translate3d(0, 0, 0) rotate(45deg);
      }
      100% {
        width: 0.2em;
        height: 0.5em;
        border-color: #212121;
        transform: translate3d(0, -0.5em, 0) rotate(45deg);
      }
    }
    & label {
      font-weight: 400;
      font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-18);
      margin: 0 0 0.75rem 0;
      display: flex;
      gap: 1.5rem;
      & a {
        white-space: nowrap;
        color: $mainColor;
        position: relative;
        &:hover::after,
        &:focus::after {
          opacity: 1;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          top: 90%;
          left: 0;
          pointer-events: none;
          background: $mainColor;
          opacity: 0;
          transition: opacity 0.2s ease;
        }
      }
    }
  }
}

#step2 {
  margin: 0;
  & .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem auto;
    & .selected-option {
      padding: 1rem 2.5rem 1rem 1rem;
      &::before {
        display: none;
      }
    }
    & .hero-exchange .btn {
      display: none;
    }
  }
}

.exchange-inputs-wrapper {
  display: flex;
  gap: 1rem;
  & > div {
    flex: 1 1 auto;
    & input,
    .selected-option {
      cursor: default;
    }
    & h3 {
      margin: 0 0 0.5rem 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

.wallet-address {
  display: flex;
  flex-direction: column;
  & label {
    font-weight: 700;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    margin: 0 0 0.5rem 0;
    & span {
      color: $mainColor;
    }
  }
}

.wallet-info {
  display: flex;
  gap: 1rem;
  & .input-wrapper {
    padding: 0 20px 0 0;
    & input {
      font-weight: 400;
      font-size: clamp($text-14, 0.7679rem + 0.5357vw, $text-20);
      color: #aaa;
    }
  }
  & i {
    cursor: pointer;
    @include hoverGlow;
  }
}

.qr {
  border: 1px solid #464646;
  border-radius: 12px;
  padding: 16px;
  transition: border 0.2s ease;
  &:hover {
    border: 1px solid $mainColor;
  }
}

.exchange-status {
  display: flex;
  gap: 1rem;
  margin: 2rem 0 0 0;
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1 1 auto;
    font-weight: 700;
    font-size: $text-16;
    & i[class='ri-refresh-line mainColored']::before {
      animation: rotation 2s linear infinite;
      transform: scale(2);
    }
  }
  & > p {
    font-weight: 400;
    font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
    color: #cfcfcf;
  }
}

.confirmation {
  position: fixed;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  background-color: #242424;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: $text-18;
  bottom: 10px;
  transition: all 0.5s ease-in;
  box-shadow: 0 0 10px #00000050;
  &.successfull {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: fit-content;
  aspect-ratio: 1 / 1;
  min-width: 300px;
  padding: 3rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  & button {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
    & .close-btn {
      transform: scale(1.6);
      transition: color 0.3s ease;
      &:hover {
        color: $mainColor;
      }
    }
  }
  & img {
    max-width: 300px;
    max-height: 300px;
    min-width: 200px;
    min-height: 200px;
  }
}
.exchange-details h3 {
  font-weight: 700;
  font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
  margin: 0 0 1.5rem 0;
}
.details-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  & li {
    &:nth-child(4) i,
    &:nth-child(5) i {
      display: none;
    }
    & span {
      font-weight: 400;
      font-size: clamp($text-14, 0.8393rem + 0.1786vw, $text-16);
      color: #aaa;
    }
    & p {
      word-break: break-all;
      margin: 0.5rem 0 0 0;
      font-weight: 400;
      font-size: clamp($text-16, 0.8571rem + 0.7143vw, $text-24);
      display: flex;
      gap: 1rem;
      align-items: center;
      & i {
        cursor: pointer;
        &:hover::before {
          transition: all 0.2s ease;
          filter: drop-shadow(0 0 25px rgba(104, 255, 200, 1));
        }
      }
    }
  }
}

#step3 .container {
  padding: 3rem;
  & .btn {
    margin: 2rem auto;
    box-shadow: 0 0 40px 0 rgba(104, 255, 200, 0.19);
    padding: 24px 36px;
    max-width: 892px;
    @include hoverGlow;
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 880px) {
  #exchange {
    padding: 1rem 0;
  }
  #step1 .container {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .exchange-steps {
    margin: 0;
  }
  .exchange-steps__item:not(.current) {
    display: none;
  }
  .exchange-steps.final {
    margin-bottom: 1.5rem;
  }
  .exchange-steps__item.final {
    display: flex;
    justify-content: center;
  }
  .exchange-steps__item.current {
    flex: 1 1 100%;
    justify-content: center;
  }
  .exchange-inputs-wrapper {
    flex-direction: column;
  }
  .exchange-status {
    flex-direction: column;
  }
  #step3 .container {
    padding: 1rem 0;
  }
  #step3 .container .btn {
    margin: 1rem auto 2rem;
  }
}
@media (max-width: 400px) {
  .user-data {
    gap: 1.5rem;
  }
  .user-input__checkbox label {
    gap: 0.5rem;
  }
}
@media (max-width: 320px) {
}
