* {
  box-sizing: border-box;
  border: none;
  background: transparent;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
ul,
li,
body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

::-moz-placeholder {
  font-family: Onest, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
li,
a,
p,
span,
::placeholder,
input,
div,
button {
  font-family: Onest, sans-serif;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
}

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #fff;
}

*:focus {
  outline: none;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
#root {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

::-moz-selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

::selection {
  background-color: rgba(255, 255, 255, 0.2470588235);
  color: inherit;
}

.container {
  width: min(100% - 6rem, 1344px);
  margin: 0 auto;
}

.container-890 {
  width: min(100% - 4rem, 890px);
  margin: 0 auto;
}

body {
  color: #fff;
  background: #171717;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  flex: 1 1 auto;
}

section:not(:last-child) {
  margin: 0 0 8rem 0;
}

label {
  cursor: pointer;
}

[class^=ri]::before {
  display: inline-block;
  font-size: 24px;
}

.section-title {
  font-size: clamp(2rem, 1.7143rem + 1.4286vw, 3rem);
  margin: 0 0 1.5rem 0;
}

.dfb {
  padding: 24px;
  border-radius: 24px;
  background: #242424;
}

.main-color-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: #68ffc8;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.main-color-btn:hover {
  box-shadow: 0px 2px 71.2px -1px rgba(104, 255, 200, 0.4);
}

.btn {
  cursor: pointer;
  display: flex;
  padding: 12px 36px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
}

.blue-btn {
  background: #406cff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  transition: box-shadow 0.2s ease;
}
.blue-btn:hover {
  box-shadow: 0px 0px 50px 5px rgba(64, 109, 255, 0.6431372549);
}

.mainColored {
  color: #68ffc8;
}

[class^=ri-],
[class*=" ri-"] {
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

.error {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #242424;
  font-size: 0.85em;
  color: rgb(228, 46, 46);
  font-weight: normal;
}

@media (max-width: 1024px) {
  section:not(:last-child) {
    margin: 0 0 3rem 0;
  }
  .container-890 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: min(100% - 4rem, 1344px);
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .container {
    width: min(100% - 2rem, 1344px);
    margin: 0 auto;
  }
  .dfb {
    padding: 16px;
  }
}
.hero-exchange {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  flex: 0 1 600px;
}
.hero-exchange > div:nth-child(1),
.hero-exchange > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.hero-exchange > div h3 {
  color: #fff;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-self: normal;
}
.hero-exchange .btn {
  position: relative;
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  width: calc(100% - 4px);
  align-self: center;
}
.hero-exchange .btn i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

.exchange-swap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}
.exchange-swap p,
.exchange-swap form {
  flex: 1 1 auto;
}
.exchange-swap .svg-container {
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #464646;
  transition: all 0.3s ease;
}
.exchange-swap .svg-container:hover {
  border: 1px solid #68ffc8;
}
.exchange-swap .svg-container svg {
  width: 24px;
  height: 24px;
}

.exchange-values {
  color: #cfcfcf;
  font-size: clamp(0.875rem, 0.7679rem + 0.5357vw, 1.25rem);
  font-weight: 400;
}

.exchange-type {
  display: flex;
  align-items: center;
}
.exchange-type label {
  cursor: pointer;
}

.exchange-to .input-wrapper .input {
  padding: 16px 16px 16px 40px;
}
.exchange-to .input-wrapper::before {
  content: "~";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 1.3rem;
}

.exchange-type input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; /* remove default */
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background-color: hsla(0, 0%, 0%, 0.2);
  background-image: -webkit-radial-gradient(transparent 25%, #68ffc8 30%, #68ffc8 100%);
  background-repeat: no-repeat;
  transition: background-position 0.15s cubic-bezier(0.8, 0, 1, 1), transform 0.25s cubic-bezier(0.8, 0, 1, 1), border 0.25s ease;
  outline: none;
}
.exchange-type input[type=radio]:not(:checked) {
  border: 1px solid hsla(0, 0%, 100%, 0.15);
}
.exchange-type input[type=radio]:hover {
  border: 1px solid #68ffc8;
}

.exchange-type input[type=radio]:checked {
  transition: background-position 0.2s 0.15s cubic-bezier(0, 0, 0.2, 1), transform 0.25s cubic-bezier(0, 0, 0.2, 1);
}
.exchange-type input[type=radio]:checked + label {
  font-weight: 500;
}

.exchange-type input[type=radio]:active {
  transition: transform 0.1s cubic-bezier(0, 0, 0.2, 1);
}

/* The left/right direction logic on radio inputs */
.exchange-type input,
.exchange-type input:active {
  background-position: 24px 0;
}

.exchange-type input:checked {
  background-position: 0 0;
}

.exchange-type input:checked ~ input,
.exchange-type input:checked ~ input:active {
  background-position: -24px 0;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.select-currency img {
  width: 24px;
  height: 24px;
}

.selected-option {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 4.5rem 1rem 0;
  cursor: pointer;
}
.selected-option::before {
  content: url("/src/images/arrow-down-s-fill.svg");
  position: absolute;
  top: 50%;
  transform: rotateX(0deg) translateY(-50%);
  right: 1rem;
  pointer-events: none;
  transition: all 0.3s ease;
}
.selected-option[data-is-open=true]::before {
  transform: rotateX(-180deg) translateY(50%);
}
.selected-option span {
  font-weight: 700;
  font-size: clamp(0.875rem, 0.7679rem + 0.5357vw, 1.25rem);
  color: #fff;
}
.selected-option .web {
  color: black;
  font-size: 0.7em;
}

.currency-search {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  order: -2;
}
.currency-search h3 {
  font-weight: 700;
  font-size: clamp(1rem, 0.8571rem + 0.7143vw, 1.5rem);
  order: 1;
  display: block;
}

.currency-search-field {
  order: 2;
  flex: 1 1 auto;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.currency-search-field.focused + h3 {
  display: none;
}
.currency-search-field.focused input {
  opacity: 1;
  max-width: 100%;
}
.currency-search-field input {
  flex: 1 1 auto;
  opacity: 0;
  max-width: 0;
  transition: all 0.3s ease;
  padding: 0 0 0.5rem 0;
  color: #fff;
  font-size: clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem);
  border-bottom: 1px solid #cfcfcf;
}
.currency-search-field input::-moz-placeholder {
  font-weight: 400;
  font-size: 1rem;
  color: #b7b7b7;
}
.currency-search-field input::placeholder {
  font-weight: 400;
  font-size: 1rem;
  color: #b7b7b7;
}
.currency-search-field i {
  height: 24px;
  width: 24px;
  pointer-events: none;
}

.options {
  position: absolute;
  z-index: 2;
  top: calc(100% + 10px);
  left: -24px;
  right: 0;
  width: calc(100% + 48px);
  transition: all 0.3s ease;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0 14px 84px 0 rgba(0, 0, 0, 0.6);
  background: #242424;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: clip;
  scrollbar-width: thin;
  scrollbar-color: #242424 rgba(0, 0, 0, 0.6);
  gap: 1rem;
  max-height: 400px;
}

.option {
  cursor: pointer;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #464646;
  transition: border 0.3s ease;
}
.option.selected {
  background-color: #68ffc8;
  order: -1;
}
.option.selected span:nth-child(2) {
  font-weight: 700;
  color: black;
}
.option.selected span.web {
  border: 1px solid black;
}
.option.selected span:last-child {
  color: black;
}
.option:hover {
  border: 1px solid #68ffc8;
}
.option img {
  width: 24px;
  height: 24px;
}
.option span:nth-child(2) {
  font-weight: 700;
  font-size: clamp(0.875rem, 0.7679rem + 0.5357vw, 1.25rem);
  color: #fff;
}
.option span:last-child {
  flex: 1 1 auto;
  text-align: right;
  font-weight: 400;
  font-size: 1rem;
  color: #b7b7b7;
}

.web {
  border: 1px solid #68ffc8;
  background-color: #68ffc8;
  border-radius: 12px;
  padding: 3px 5px;
  color: #000;
  font-size: 0.8em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}

@media (max-width: 1070px) {
  .hero-exchange {
    flex: 1 1 auto;
  }
}
@media (max-width: 768px) {
  .hero-exchange .btn {
    padding: 20px 36px 20px 12px;
  }
}
@media (max-width: 560px) {
  .hero-exchange > div:nth-child(1),
  .hero-exchange > div:nth-child(3) {
    gap: 1rem;
  }
  .hero-exchange > div {
    padding: 1rem;
    border-radius: 16px;
  }
  .option {
    gap: 12px;
  }
}
@media (max-width: 400px) {
  .selected-option,
  .input {
    padding-block: 12px;
  }
  .input-wrapper {
    border-radius: 8px;
  }
  .exchange-to .input-wrapper .input {
    padding: 16px 16px 16px 32px;
  }
  .exchange-to .input-wrapper::before {
    left: 12px;
  }
  .hero-exchange .btn {
    padding: 12px 36px 12px 12px;
  }
}/*# sourceMappingURL=exchangeBlock.css.map */